import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Cinuvee from './Component/Cinuvee'
import TvView from './Component/TvView'
import App from './App'
import PrivacyPolitc from './PrivacyPolitc'
import MainSelector from './Component/MainSelector'
import Gamers from './Component/Gamers'
import Podcast from './Component/Podcast'
import Backgrounds from './Component/Moduls/Backgrounds'
import Homepage from './Component/Mapp/Homepage'
import Portada from './Component/Portada'
import ToolsUse from './Component/Moduls/TVFrame/ToolsUse'
import AdminProfile from './Component/Moduls/ProfileFrame/AdminProfile'
import CardBuy from './Component/CardBuy'
import BackgroundSVG from './BackgroudSVG'
import nube01 from './Assets/nubes/01.png'
import nube02 from './Assets/nubes/02.png'
import nube03 from './Assets/nubes/03.png'
import nube04 from './Assets/nubes/04.png'
import nube05 from './Assets/nubes/05.png'
import AnimatedImages from './Component/AnimatedImages'



export default class RouterApp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      imagePaths: [nube01, nube02, nube03, nube04, nube05], 
    };
  }


  render() {
    return (
      <>
        <AnimatedImages className="App-header " imagePaths={this.state.imagePaths}/>
      
        {window.location.pathname !== '/' && window.location.pathname !== '/homepagemaszov' && <MainSelector />}
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Portada />} />
              <Route path='/nuveeplus' element={<App />} />
              <Route path='/cinuvee/:movie/:id' element={<Cinuvee />} />
              <Route path='/Profile' element={<AdminProfile />} />
              <Route path='/NuveeTV' element={<TvView />} />
              <Route path='/Gamers' element={<Gamers />} />
              <Route path='/Musica' element={<Podcast />} />
              <Route path='/ModuleTest' element={<Backgrounds />} />
              <Route path='/homepagemaszov' element={<Homepage />} />
              <Route path='/Tools' element={<ToolsUse />} />
              <Route path='/Cardmade' element={<CardBuy />} />
              <Route path='/privacitynuvee' element={<PrivacyPolitc />} />
            </Routes>
          </BrowserRouter>
        </div>

      </>
    )
  }
}
