import React, { Component } from 'react'
import axios from 'axios'
import { Button, Card, CardBody, CardHeader, CardImg, Carousel, Col, FormControl, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { LoadHome } from '../store/Metadata'
import Imgmas from '../Assets/unmas.png'
import Cinuvee from './Cinuvee'
import { Navigate } from 'react-router-dom';
import MetaContext from './MetaContext'
import { Firestore, arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import db from '../firebase'
import { LoadContent } from '../store/Metadata'
import ReactPlayer from 'react-player'
import { BsHeart, BsHeartFill } from 'react-icons/bs'
import Spinner from 'react-bootstrap/Spinner';


class Home extends Component {

  constructor(props) {
    super(props)
    this.state = LoadHome.init
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
  
  }


  handleClick(a, b) {
    this.state = {
      metaStateA: a,
      metaStateB: b,
    };
    this.shekIN()
    window.location = '/cinuvee/' + b + '/' + a;
  }

  shekIN() {

    <MetaContext.Provider value={this.state.metaStateA}>
      {this.props.children}
    </MetaContext.Provider>
  }

  async componentDidMount() {
    setTimeout(() => {
      this.setState({
        loadResurses: true
      });
    }, 1200);    
    const docs = [];
    const querySnapshot = await getDocs(collection(db, 'meta_content_files'));
    console.log(querySnapshot.size)
    querySnapshot.forEach((doc, index, array) => {
      docs.push({
        id: doc.id, data: doc.data(),
        type_data: doc.data().media_type,
        plataforme_data: doc.data().plataformChose,
        serie_list: doc.data().serieList,
        seasson_code: doc.data().seassonNo,
      });
      console.log(doc.data().plataformChose)
      this.setState((prevState) => ({
        docs,
      }));
    })  
    try {
      const res = await axios.get('https://api.themoviedb.org/3/trending/all/day?api_key=e3c22a22d27dfea3ea6cce8d2a6fb2bf&language=es-ES');      
      this.setState({
        resurcesTx: res.data.results,        
      });         
      const res2 = await axios.get('https://api.themoviedb.org/3/movie/upcoming?api_key=00f019191c205c1208fd3d615b9fb303&language=es-ES');
      this.setState({ resurcesTxr: res2.data.results });
    } catch (err) {
      console.log(err);
    }    
  }

  async loadOptionFile(dc) {
    const reviewStates = await getDocs(collection(db, 'db:likes-movies'))
    reviewStates.forEach((moviesOver) => {
      if (dc === moviesOver.data().nameMovie) {
        this.setState({
          movieExist: true,
          maxNumberLike: moviesOver.data().maxNumberLike
        })
      }
    })
  }

  toggleOnLikeIt(bool) {
    const reviewLikes = async () => {
      const reviewStates = await getDocs(collection(db, 'db:likes-movies'))
      reviewStates.forEach((moviesOver) => {
        if (this.state.docTitleLook === moviesOver.data().nameMovie) {
          const quitLikeIt = async () => {
            const docRef = doc(db, "db:likes-movies", this.state.docTitleLook);
            const userRef = doc(db, "user_default_values", localStorage.getItem('userState'));
            let truePut = Number(moviesOver.data().maxNumberLike) - 1
            await updateDoc(docRef, {
              maxNumberLike: truePut
            });
            await updateDoc(userRef, {
              metaLikeIt: arrayRemove(this.state.docTitleLook)
            })
            this.setState({
              togLikeIt: false,
              maxNumberLike: truePut
            })
          }
          quitLikeIt()
        }
      })
    }
    reviewLikes()
  }

  async onLikeActive(bool) {

    const dateTo = new Date()
    let dateNewOff = dateTo.getFullYear() + "" + (dateTo.getMonth() + 1) + "" + dateTo.getDate()
    console.log(this.state.docTitleLook)
    const docLikesOver = doc(db, 'db:likes-movies', this.state.docTitleLook);
    const userRef = doc(db, "user_default_values", localStorage.getItem('userState'));
    const reviewUserFav = await getDoc(userRef)
    const updateOrCheck = async () => {
      this.setState({ togLikeIt: true })
      if (this.state.movieExist === true) {
        if (reviewUserFav.data().metaLikeIt) {
          const getLikes = await getDoc(docLikesOver)
          let truePut = Number(getLikes.data().maxNumberLike) + 1
          this.setState({ maxNumberLike: truePut })
          await updateDoc(docLikesOver, {
            maxNumberLike: truePut,
            lastLikeIt: dateNewOff,
          });
          await updateDoc(userRef, {
            metaLikeIt: arrayUnion(this.state.docTitleLook)
          })
        }
      }
      else {
        await setDoc(docLikesOver, {
          nameMovie: this.state.docTitleLook,
          fistLikeIt: dateNewOff,
          idItem: "valueCategory",
          keyRndAdmin: "564185hohoakaak",
          lastLikeIt: dateNewOff,
          maxNumberLike: "1"
        });
      }
    }

    if (reviewUserFav.data().metaLikeIt) {
      try {
        reviewUserFav.data().metaLikeIt.forEach((movieExist) => {
          if (movieExist === this.state.docTitleLook) {
            this.toggleOnLikeIt()
          } else {
            updateOrCheck()
          }
        })
      }
      catch (e) {
      }
    }
  }

  render() {
    const { docs, showInfo, urlVideoNoCodec } = this.state;
    return (
      <>
        {
          this.state.loadResurses === true ? <>
         {/*  <Carousel variant='dark' >
              {this.state.storeImg.map((a, i) => (
                <Carousel.Item key={i} interval={2500}>
                  <Card >
                    <Card.Body className='purple-card z-25'>
                      <Row>
                        <Col>
                          <Card.Img className='img-height-100 carousel-img img full-screen-image img' src={a.pathImg}></Card.Img>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Carousel.Item>
              ))}
            </Carousel>*/}
            
            <section  className='t-active z-25' key={11896}>
              <Table striped hover variant='dark' className='no-border t-active'>
                <thead>
                  <tr>
                    {this.state.miniStoreImg.map((moviesPath, k) => (
                      <th key={`movies_${k}`} style={{ backgrondColor: 'transparent!important' }}>
                        <img onClick={() => { window.location = '/nuveeplus#' + moviesPath.name + ''; console.log(moviesPath.name) }} className='z-25' width={'100%'} src={moviesPath.pathImg} />
                      </th>
                    ))}
                  </tr>
                </thead>
              </Table>
            </section>
            <div className='container-movies' >
              <br />
              <div className='justify-container-center j-i-c margin-ltr-5 ' key={1858} >
                <br />
                <h5 className='c-white txt-left neon-text  z-25' >Novedades</h5>
                <br />
                <br />
                <div className="z-25 t-active" key={118912}>
                  <section id='novedades' className='horizontal-scroll-container t-active' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      <div key={`novedades_${doc.id}`} className='horizontal-scroll-item t-active' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {
                          this.loadOptionFile(doc.data.titleMovie)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie,
                            serieEnable: doc.data.radioValue,
                            capConList: doc.serie_list
                          });
                        }} />
                      </div>
                    ))}
                  </section>
                </div>
                <br />
                <h5 className='c-white txt-left neon-text t-active z-25' >Series</h5>
                <br />
                <br />
                <div key={'12912'} className="z-25 t-active" >
                  <section id='series' className='horizontal-scroll-container t-active' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      doc.type_data === 'tv' ? <div key={`series_${doc.id}_${idx}`} className='horizontal-scroll-item' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {                      
                          this.loadOptionFile(doc.data.titleMovie)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie,
                            serieEnable: doc.data.radioValue,
                            capConList: doc.serie_list,
                            seasson: doc.seasson_code
                          });
                        }} />
                      </div> : null
                    ))}
                  </section>
                </div>
                <br />
                <br />
                <h5 className='c-white txt-left neon-text  z-25' >Nuvee+</h5>
                <br />
                <br />
                <div className="z-25 " key={148912}>
                  <section id='nuvee' className='horizontal-scroll-container' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      doc.plataforme_data === 'NUVEE+' ? <div key={`nuvee_${idx}`} className='horizontal-scroll-item' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {
                          this.loadOptionFile(doc.data.titleMovie)
                          console.log(doc.serie_list)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie,
                            serieEnable: doc.data.radioValue,
                            capConList: doc.serie_list,
                          });
                        }} />
                      </div> : null
                    ))}
                  </section>
                </div>
                <br />
                <br />
                <h5 className='c-white txt-left neon-text  z-25' >Peliculas</h5>
                <br />
                <br />
                <div className="z-25 " key={11432}>
                  <section id='peliculas' className='horizontal-scroll-container' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      doc.type_data === 'movie' ? <div key={`peliculas_${doc.id}`} className='horizontal-scroll-item' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {
                          this.loadOptionFile(doc.data.titleMovie)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie
                          });
                        }} />
                      </div> : null
                    ))}
                  </section>
                </div>
                <br />
                <br />
                <h5 className='c-white txt-left neon-text  z-25' >Netflix</h5>
                <br />
                <br />
                <div className="z-25 " key={1185432}>
                  <section id='netflix' className='horizontal-scroll-container' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      doc.plataforme_data === 'Netflix' ? <div key={`netflix_${doc.id}`} className='horizontal-scroll-item' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {
                          this.loadOptionFile(doc.data.titleMovie)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie,
                            serieEnable: doc.data.radioValue,
                            capConList: doc.serie_list,
                            seasson: doc.seasson_code
                          });
                        }} />
                      </div> : null
                    ))}
                  </section>
                </div>
                <br />
                <br />
                <h5 className='c-white txt-left neon-text  z-25'  >MAX</h5>
                <br />
                <br />
                <div className="z-25 ">
                  <section id='max' className='horizontal-scroll-container' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      doc.plataforme_data === 'HBO' ? <div key={`max_${doc.id}`} className='horizontal-scroll-item' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {
                          this.loadOptionFile(doc.data.titleMovie)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie,
                            serieEnable: doc.data.radioValue,
                            // capConList: doc.serie_list
                          });
                        }} />
                      </div> : null
                    ))}
                  </section>
                </div>
                <br />
                <br />
                <h5 className='c-white txt-left neon-text  z-25' >Paramount+</h5>
                <br />
                <br />
                <div className="z-25 " key={11884}>
                  <section id='paramount' className='horizontal-scroll-container' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      doc.plataforme_data === 'Paramount' ? <div key={`paramount_${doc.id}`} className='horizontal-scroll-item' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {
                          this.loadOptionFile(doc.data.titleMovie)
                          console.log(doc.serie_list)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie,
                            serieEnable: doc.data.radioValue,
                            capConList: doc.serie_list,
                          });
                        }} />
                      </div> : null
                    ))}
                  </section>
                </div>
                <br />
                <br />
                <h5 className='c-white txt-left neon-text  z-25-plus' >Prime</h5>
                <br />
                <br />
                <div className="z-25 " key={23912}>
                  <section id='prime' className='horizontal-scroll-container' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      doc.plataforme_data === 'Prime' ? <div key={`prime_${doc.id}`} className='horizontal-scroll-item' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {
                          this.loadOptionFile(doc.data.titleMovie)
                          console.log(doc.serie_list)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie,
                            serieEnable: doc.data.radioValue,
                            capConList: doc.serie_list,
                          });
                        }} />
                      </div> : null
                    ))}
                  </section>
                </div>
                <br />
                <br />
                <h5 className='c-white txt-left neon-text  z-15' >Disney +</h5>
                <br />
                <br />
                <div className="z-25 " key={1185412}>
                  <section id='disney' className='horizontal-scroll-container' style={{ width: '100%' }}>
                    {docs.map((doc, idx) => (
                      doc.plataforme_data === 'Disney+' ? <div key={`disney_${doc.id}`} className='horizontal-scroll-item' style={{ width: '100%' }}>
                        <img src={doc.data.imgTitle} width={'100%'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={() => {
                          this.loadOptionFile(doc.data.titleMovie)
                          console.log(doc.serie_list)
                          this.setState({
                            showInfo: true,
                            urlVideoNoCodec: doc.data.urlDescarga,
                            docTitleLook: doc.data.titleMovie,
                            serieEnable: doc.data.radioValue,
                            capConList: doc.serie_list,
                          });
                        }} />
                      </div> : null
                    ))}
                  </section>
                </div>
                <br />
              </div>
            </div>           
            <footer style={{ height: '200px!important' }}></footer>
            <Modal key={this.state.docLikesOver} className='z-25-plus p-absolute' onHide={() => this.setState({ showInfo: false, movieExist: false, togLikeIt: false })} show={showInfo}>
              <ModalHeader closeButton />
              <ModalTitle>{this.state.docTitleLook}</ModalTitle>
              <ModalBody>
                <ReactPlayer width={'100%'} url={this.state.urlVideoNoCodec} controls={true} />
                <br />
                {this.state.seasson === 'Temporada 1' && this.state.serieEnable === 'Serie' ? (<div>
                  <span><Button variant='success'>Temporada 1</Button></span>
                </div>) : null}
                <br />
                {this.state.serieEnable === 'Serie' ? (
                  <select onChange={(e) => { this.setState({ urlVideoNoCodec: this.state.capConList[e.target.value].urlDescarga }) }}>
                    {Object.keys(this.state.capConList).map((index) => (
                      <option key={index} value={index}>
                        {this.state.capConList[index].capN}
                      </option>
                    ))}
                  </select>
                ) : null}
              </ModalBody>
              <ModalFooter>
                <OverlayTrigger
                  className='z-25-plus'
                  key='top'
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip className='z-25-plus' id={`tooltip-top`}><strong>{this.state.maxNumberLike}</strong></Tooltip>}>
                  <Button key={this.state.docLikesOver} variant="outline-dark" id="btn-liked" onClick={() => {
                    this.state.togLikeIt === false ? this.onLikeActive() : this.toggleOnLikeIt();
                  }}>{this.state.togLikeIt === false ? <BsHeart /> : <BsHeartFill />}
                  </Button>
                </OverlayTrigger>
              </ModalFooter>
            </Modal></> :
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>}

      </>
    )
  }
}

export default Home


