import React, { Component } from 'react'
import { Nav, Col, Row, DropdownButton, Navbar, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import logonuvee from '../Assets/logonuvee.png'
import { FiPlayCircle, FiMonitor, FiMusic } from 'react-icons/fi'
import { IoGameControllerOutline } from 'react-icons/io5'
import { MetaStore } from '../store/Metadata'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import db from '../firebase'
import { BsList, BsFillPersonFill, BsFillCircleFill } from "react-icons/bs";
import { TbBulldozer } from "react-icons/tb";
import NuveeBout from './NuveeBout'
import NavApp from './Moduls/Frames/NavApp'
import { GiConsoleController } from "react-icons/gi";
import { FcClapperboard } from "react-icons/fc";


export default class MainSelector extends Component {

  constructor(props) {
    super(props)
    this.state = MetaStore.navOpselector
    this.aboutNuvee = this.aboutNuvee.bind(this)
  }




  handleButton(v) {
    const parsedIndex = parseInt(v);
    if (parsedIndex >= 0 && parsedIndex || parsedIndex == 0) {
      window.location.replace(this.state.urlSelect[parsedIndex]);
    } else {
      return <h1>Invalid Index</h1>;
    }
  }

  aboutNuvee() {
    console.log('set')
    this.setState({ modalAbout: true })
  }

  modalOff() {
    this.setState({ modalAbout: false })
  }


  render() {

    return (<>
      <Nav className='nav-web color-icons backnav-color z-25' style={{ display: 'none!important', width: '100%' }} activeKey="/home">
        <Row className='width-100-space justify-content-md-center'>
          <Col className='no-row-100'>
            <Nav.Item >
              <div className='back-icon-logo' id='lograd'>
                <Nav.Link><img alt='no-load.jpg' onClick={() => { this.aboutNuvee() }} src={logonuvee} className='img-size-50' />

                </Nav.Link>
              </div>
            </Nav.Item>
          </Col>
          <Col xs={7} >
            <Row>
              {this.state.mainOption.map((d, i) => {
                return (
                  <Col key={i}  >
                    <Nav.Item >
                      <Nav.Link onClick={() => this.handleButton(i)} >
                        {d}
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                )
              })}
            </Row>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link href={"/Profile"} className='right-0'>{localStorage.getItem('userState')}</Nav.Link>
            </Nav.Item>
          </Col>
        </Row>
      </Nav>
      <Navbar expand="lg" className="nav-mobil navbehind t-active " style={{ position: 'fixed!important', zIndex: '2600' }}>
        <Nav className="justify-content-center" style={{ flexDirection: 'row' }}>
          <Nav.Link href="/NuveeTv" style={{ position: 'absolute', left: '5%', top: '0%' }}> <NavApp effect="static" />
          </Nav.Link>
          <Nav.Link href="/nuveeplus#" style={{ position: 'absolute', left: '23%' }}><FcClapperboard />
            <br />
            Nuvee+</Nav.Link>
          <Nav.Link onClick={() => { this.aboutNuvee() }}><img src={this.state.logoNuveeOn} width={'15%'} /></Nav.Link>
          <Nav.Link onClick={()=>{alert('Volver despues, en construccion')}} style={{ position: 'absolute', left: '64%' }}><TbBulldozer className='icon-color-purpleblue'/><br />Adds</Nav.Link>
          <Nav.Link href={"/Gamers"} style={{ position: 'absolute', left: '80%' }}><GiConsoleController className='icon-color-purpleblue'/><br />NuveePlay</Nav.Link>
        </Nav>
      </Navbar>
      <Modal className='z-25-plus p-absolute' show={this.state.modalAbout} onHide={() => { this.modalOff() }} closeButton>
        <ModalBody>
          <NuveeBout />
        </ModalBody>
        <ModalFooter>
          <img src={this.state.TagMzv} width={'10%'}  />
        </ModalFooter>
      </Modal>
    </>
    )
  }
}
