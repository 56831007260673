import React, { useEffect, useRef, useState } from "react";

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min) + min);

const AnimatedImages = ({ imagePaths }) => {
  const canvasRef = useRef(null);
  const [images, setImages] = useState([]);
  const [delta, setDelta] = useState(1);

  useEffect(() => {
    const loadedImages = [];
    let loadedCount = 0;

    imagePaths.forEach((path, index) => {
      const img = new Image();
      img.src = path;
      img.onload = () => {
        loadedImages[index] = img;
        loadedCount++;
        if (loadedCount === imagePaths.length) {
          setImages(loadedImages);
        }
      };
    });
  }, [imagePaths]);

  useEffect(() => {
    if (!canvasRef.current || images.length === 0) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    class ImageObject {
      constructor(x, y, img) {
        this.x = x;
        this.y = y;
        this.image = img;
        this.opacity = 0.2;
        this.scale = 0.8;
      }

      reset() {
        if (
          this.x < 0 ||
          this.x > canvas.width ||
          this.y < 0 ||
          this.y > canvas.height
        ) {
          this.opacity = 0.2;
          this.scale = 0.8;
          this.x = getRandomInt(1, canvas.width);
          this.y = getRandomInt(1, canvas.height);
        }
      }

      move() {
        const speed = 100 * delta;
        this.x += (this.x - canvas.width / 2) * (this.scale / speed);
        this.y += (this.y - canvas.height / 2) * (this.scale / speed);
        this.opacity = Math.min(this.opacity + 0.01 / delta, 0.01);
        this.scale -= 0.01 / delta;
      }

      draw() {
        this.reset();
        this.move();
        ctx.globalAlpha = this.opacity;
        const size = this.image.width * this.scale;
        ctx.drawImage(
          this.image,
          this.x - size / 2,
          this.y - size / 2,
          size,
          size
        );
        ctx.globalAlpha = 1;
      }
    }

    const generateImages = (num, imgList) => {
      return Array.from({ length: num }, () => {
        const img = imgList[getRandomInt(0, imgList.length)];
        return new ImageObject(
          getRandomInt(1, canvas.width),
          getRandomInt(1, canvas.height),
          img
        );
      });
    };

    const imageObjects = generateImages(50, images);

    const update = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      imageObjects.forEach((obj) => obj.draw());
      requestAnimationFrame(update);
    };

    update();

    const handleScroll = () => {
      setDelta(window.scrollY * 0.01 + 0.5);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      imageObjects.forEach((obj) => {
        obj.x = getRandomInt(1, canvas.width);
        obj.y = getRandomInt(1, canvas.height);
      });
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [images, delta]);

  return <canvas className="p-absolute" ref={canvasRef} style={{ display: "block" }} />;
};

export default AnimatedImages;
